import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '', 
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {path: 'home', loadComponent: () => import('./features/home/home.component')},
  {path: '404', loadComponent: () => import('./core/components/not-found/not-found.component').then(m => m.NotFoundComponent)},
  {path: 'movie', loadChildren: () => import('./features/content/content.routes').then(m => m.CONTENT_ROUTES)},
  {path: 'tv', loadChildren: () => import('./features/content/content.routes').then(m => m.CONTENT_ROUTES)},
  {path: 'collection', loadChildren: () => import('./features/content/content.routes').then(m => m.CONTENT_ROUTES)},
  {path: 'search', loadComponent: () => import('./features/search/search.component')},
  {
    path: 'profile', 
    loadComponent: () => import('./features/personal-info/personal-info.component'),
    canActivate: [authGuard]
  },
  {
    path: 'sharedlist', 
    loadComponent: () => import('./features/shared-list/shared-list.component'),
    canActivate: [authGuard]
  },
  {path: '**', redirectTo: '404'}
];
